import { immerable } from 'immer';
import merge from 'lodash/merge';
import { nanoid } from 'nanoid';

import { OptionModel } from '@/common/models/OptionModel';
import { MutateAction } from '@/common/models/Updates';
import { mapArray, tryRemoveFromArray } from '@/common/utils/ArrayFunctions';
import { classFromJsonOrFallback } from '@/common/utils/TypeFunctions';

export class SchemaDefinition {
  [immerable] = true;
  id: string;
  name: string;
  description?: string;
  items: SchemaItemDefinition[];

  get hasItems() {
    return !!this.items?.length;
  }

  constructor(props?: Partial<SchemaDefinition>) {
    props = props || {};
    Object.assign(this, props);
    if (!this.id) this.id = nanoid();
    this.items = mapArray(props.items, (x) => new SchemaItemDefinition(x));
  }

  addItem(item: SchemaItemDefinition) {
    this.items.push(item);
  }

  deleteItem(id: string) {
    tryRemoveFromArray(this.items, (x) => x.id === id);
  }

  mutateItem(id: string, action: MutateAction<SchemaItemDefinition>) {
    const index = this.items.findIndex((x) => x.id === id);
    if (index >= 0) {
      action(this.items[index]);
    }
  }

  toOptions(): OptionModel<string>[] {
    return this.items.map((x) => x.toOption(this.name), this);
  }

  static fromJsonOrNew(json: string) {
    return classFromJsonOrFallback(
      SchemaDefinition,
      json,
      new SchemaDefinition()
    );
  }
  static resolveScope(schemas: SchemaDefinition[]): Record<string, string> {
    const scope: Record<string, string> = {};
    schemas.forEach((s) => {
      if (!s.hasItems && !!s.name) {
        scope[s.name] = s.name;
      } else {
        s.items.reduce((scope, option) => {
          const properties = `${s.name}.${option.name}`.split('.');
          const newOption = createObj(properties, option.name);
          return merge(scope, newOption);
        }, scope);
      }
    });
    return scope;
  }

  static flattenItems(schemas: SchemaDefinition[]) {
    return schemas.reduce((all: SchemaItemDefinition[], current) => {
      current.items.forEach((i) => all.push(i));
      return all;
    }, []);
  }

  static cloneArray(schemas: SchemaDefinition[]) {
    return mapArray(schemas, (x) => new SchemaDefinition(x));
  }
}

export class SchemaItemDefinition {
  [immerable] = true;
  id: string;
  name: string;
  label?: string;
  dataType: SchemaItemDataTypes;
  isRequired?: boolean;

  constructor(props?: Partial<SchemaItemDefinition>) {
    props = props || {};
    Object.assign(this, props);
    if (!this.id) this.id = nanoid();
    if (!this.dataType) {
      this.dataType = SchemaItemDataTypes.String;
    }
  }

  toOption(definitionName?: string): OptionModel<string> {
    return {
      label: this.label || this.name,
      value: !definitionName ? this.name : `${definitionName}.${this.name}`
    };
  }
}

export enum SchemaItemDataTypes {
  String = 'String',
  Numeric = 'Numeric',
  Boolean = 'Boolean',
  Date = 'Date',
  Object = 'Object',
  List = 'List'
}

const buildSchemaDataTypeOptions = () => {
  const options: OptionModel<SchemaItemDataTypes>[] = [];
  Object.values(SchemaItemDataTypes).forEach((type) => {
    switch (type) {
      case SchemaItemDataTypes.String:
        options.push({ label: 'String', value: type });
        break;
      case SchemaItemDataTypes.Numeric:
        options.push({ label: 'Number', value: type });
        break;
      case SchemaItemDataTypes.Boolean:
        options.push({ label: 'Boolean', value: type });
        break;
      case SchemaItemDataTypes.Date:
        options.push({ label: 'Date', value: type });
        break;
      case SchemaItemDataTypes.Object:
        options.push({ label: 'Object', value: type });
        break;
      case SchemaItemDataTypes.List:
        options.push({ label: 'List', value: type });
        break;
      default:
        // noinspection UnnecessaryLocalVariableJS
        const unsupported: never = type;
        console.error('Unsupported SchemaItemDataTypes', unsupported);
        throw 'Unsupported SchemaItemDataTypes' + unsupported;
    }
  });
  return options;
};

export const SchemaItemDataTypeOptions = buildSchemaDataTypeOptions();

export interface SchemaMapRecord {
  name: string;
  mapValue: string;
}

const createObj = (keys: any[], property: string) => {
  const key = keys.shift();
  if (key === undefined) {
    return property;
  }
  return { [key]: createObj(keys, property) };
};
